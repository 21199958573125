import React, { useState } from 'react';
import { Form } from 'react-bootstrap';

import { ijroGovApi } from '../../../services/ijroGovService';

import Translate from '../../../components/lang';
import Container from '../../../components/container';
import UploadFiles from '../../../components/uploadFiles';
import EImzoModal from '../../../components/e-imzo/Modal';

import OrganizationTree from './components/OrganizationTree';

import ft from '../../../components/mainStyles.module.scss';

const Create = ({ lang, history }) => {
   const [load, setLoad] = useState(false);
   const [prots, setProts] = useState([]);
   const [errFiles, setErrFiles] = useState(false);
   const [newFiles, setNewFiles] = useState([]);
   const [uploadedFiles, setUploadedFiles] = useState([]);
   const [mainProts, setMainProts] = useState([]);
   const [errMainFiles, setErrMainFiles] = useState(false);
   const [newMainFiles, setNewMainFiles] = useState([]);
   const [uploadedMainFiles, setUploadedMainFiles] = useState([]);
   const [mainOrgIds, setMainOrgIds] = useState([]);
   const [open, setOpen] = useState(false);
   const [values, setValues] = useState({
      number: '',
      date: '',
      description: '',
      type: 'DECISION',
      link: ''
   });

   const onSuccess = (user) => {
      const mainFiles = uploadedMainFiles.map(file => ({
         file: file._id,
         path: file.path,
         size: file.size
      }));

      const attachmentFiles = uploadedFiles.map(file => ({
         file: file._id,
         path: file.path,
         size: file.size
      }));

      const data = {
         number: values.number,
         date: values.date,
         description: values.description,
         type: values.type,
         link: values.link,
         mainFile: mainFiles[0],
         attachmentFiles: attachmentFiles,
         mainOrgIds: mainOrgIds,
         pin: user.pin,
         pkcs7: user.pkcs7,
         moduleType: 'NORMATIVE',
         status: 'NEW'
      };

      setLoad(true);

      ijroGovApi.createDoc(data)
        .then(res => {
           if (res.data.success) {
              history.goBack();
           }
        })
        .catch(err => {
           console.log(err);
        })
        .finally(() => {
           setLoad(false);
        });
   }

   return (
      <Container>
         <Form className={ft.form}>
            <div className={ft.modalBtns}>
               <button
                  className={ft.close__btn}
                  onClick={() => history.goBack()}
                  type='button'
               >
                  {Translate(lang, 'global.cancel')}
               </button>

               <button
                  className={`${ft.save__btn} d-flex align-items-center ml-3`}
                  onClick={() => setOpen(true)}
                  disabled={!(
                     values.number &&
                     values.description &&
                     values.type &&
                     uploadedMainFiles.length &&
                     mainOrgIds.length
                  )}
                  type='button'
               >
                  {Translate(lang, 'global.send')}
               </button>
            </div>

            <div className='row'>
               <div className='col-md-6'>
                  <div className='row'>
                     <div className="col-md-6">
                        <Form.Label>
                           <div className={ft.lab}>{Translate(lang, 'doc.number')}</div>
                           <Form.Control
                              name='number'
                              onChange={e => {
                                 setValues({...values, number: e.target.value});
                              }}
                              value={values.number}
                              type='text'
                              required
                           />
                        </Form.Label>
                     </div>

                     <div className="col-md-6">
                        <Form.Label>
                           <div className={ft.lab}>{Translate(lang, 'doc.number')}</div>
                           <Form.Control
                              name='date'
                              onChange={e => {
                                 setValues({...values, date: e.target.value});
                              }}
                              value={values.date}
                              type='date'
                           />
                        </Form.Label>
                     </div>

                     <div className="col-md-12">
                        <Form.Label>
                           <div className={ft.lab}>{Translate(lang, 'doc.description')}</div>
                           <Form.Control
                              name='description'
                              onChange={e => {
                                 setValues({...values, description: e.target.value});
                              }}
                              value={values.description}
                              as='textarea'
                              required
                           />
                        </Form.Label>
                     </div>

                     <div className="col-md-12">
                        <Form.Label>
                           <div className={ft.lab}>{Translate(lang, 'doc.type')}</div>
                           <select
                              name='type'
                              onChange={e => {
                                 setValues({...values, type: e.target.value});
                              }}
                              value={values.type}
                           >
                              <option value='LAW'>{Translate(lang, 'doc.law')}</option>
                              <option value='PARLIAMENT_DECISION'>{Translate(lang, 'doc.parliament_decision')}</option>
                              <option value='PRESIDENTIAL_DECREE'>{Translate(lang, 'doc.presidential_decree')}</option>
                              <option value='PRESIDENTIAL_DECISION'>{Translate(lang, 'doc.presidential_decision')}</option>
                              <option value='PRESIDENTIAL_ORDER'>{Translate(lang, 'doc.presidential_order')}</option>
                              <option value='CABINET_DECISION'>{Translate(lang, 'doc.cabinet_decision')}</option>
                              <option value='CABINET_ORDER'>{Translate(lang, 'doc.cabinet_order')}</option>
                              <option value='DEPARTMENTAL_DOCUMENT'>{Translate(lang, 'doc.departmental_document')}</option>
                              <option value='ELECTION_COMMISSION'>{Translate(lang, 'doc.election_commission')}</option>
                           </select>
                        </Form.Label>
                     </div>

                     <div className="col-md-12">
                        <Form.Label>
                           <div className={ft.lab}>{Translate(lang, 'doc.link')}</div>
                           <Form.Control
                              name='link'
                              onChange={e => {
                                 setValues({...values, link: e.target.value});
                              }}
                              value={values.link}
                              type='text'
                           />
                        </Form.Label>
                     </div>

                     <div className="col-md-12">
                        <Form.Label>
                           <div className={ft.lab}>{Translate(lang, 'doc.mainFile')}</div>
                           <UploadFiles
                              title='global.mainFile'
                              multiple={false}
                              accept={['.pdf']}
                              setUploadedFiles={setUploadedMainFiles}
                              setNewFiles={setNewMainFiles}
                              setErrFiles={setErrMainFiles}
                              setProts={setMainProts}
                              newFiles={newMainFiles}
                              prots={mainProts}
                              lang={lang}
                           />
                           {errMainFiles && (
                              <span className={ft.err__mes}>
                           {Translate(lang, 'global.notUploaded')}
                        </span>
                           )}
                        </Form.Label>
                     </div>

                     <div className="col-md-12">
                        <Form.Label>
                           <div className={ft.lab}>{Translate(lang, 'doc.files')}</div>
                           <UploadFiles
                              setUploadedFiles={setUploadedFiles}
                              setNewFiles={setNewFiles}
                              setErrFiles={setErrFiles}
                              setProts={setProts}
                              newFiles={newFiles}
                              prots={prots}
                              lang={lang}
                           />
                           {errFiles && (
                              <span className={ft.err__mes}>
                           {Translate(lang, 'global.notUploaded')}
                        </span>
                           )}
                        </Form.Label>
                     </div>
                  </div>
               </div>

               <div className='col-md-6'>
                  <OrganizationTree
                     setMainOrgIds={setMainOrgIds}
                     mainOrgIds={mainOrgIds}
                     lang={lang}
                  />
               </div>
            </div>
         </Form>

         <EImzoModal {...{ lang, open, load, setOpen, onSuccess }} />
      </Container>
   )
}

export default Create;
