import React from 'react';
import cx from 'classnames'

import cls from './Items.module.css';

const Items = ({ items, selected, setSelected }) => (
   <div className={cls.list}>
      {items.map((item, key) => (
         <div
            className={cx(cls.item, item.serialNumber === selected?.serialNumber && cls.selected)}
            onClick={() => setSelected(item)}
            key={key}
         >
            <div className={cls.row}>
               <div className={cls.rowKey}>Гувоҳнома рақами:</div>
               <div className={cls.rowValue}>{item.serialNumber}</div>
            </div>

            <div className={cls.row}>
               <div className={cls.rowKey}>СТИР:</div>
               <div className={cls.rowValue}>{item.TIN}</div>
            </div>

            <div className={cls.row}>
               <div className={cls.rowKey}>ЖШШИР:</div>
               <div className={cls.rowValue}>{item.PINFL}</div>
            </div>

            <div className={cls.row}>
               <div className={cls.rowKey}>Тўлиқ исми шарифи:</div>
               <div className={cls.rowValue}>{item.CN}</div>
            </div>

            <div className={cls.row}>
               <div className={cls.rowKey}>Ташкилот:</div>
               <div className={cls.rowValue}>{item.O}</div>
            </div>

            <div className={cls.row}>
               <div className={cls.rowKey}>Гувоҳноманинг амал қилиш муддати:</div>
               {/*<div className={cls.rowValue}>{item.validFrom} - {item.validTo}</div>*/}
            </div>
         </div>
      ))}
   </div>
);

export default Items;