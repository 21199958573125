import React, { useState, useEffect, useMemo } from 'react';

import Translate from '../../lang';

import EIMZO from '../EImzo';

import Loading from '../../loading';
import Items from './components/Items';

import ft from '../../../components/mainStyles.module.scss';
import cls from './Modal.module.css';

const Modal = ({ lang, open, load, setOpen, onSuccess }) => {
   const [selected, setSelected] = useState(null);
   const [certificates, setCertificates] = useState([]);

   const EIMZOClient = useMemo(() => new EIMZO(), []);

   const onSign = () => {
      EIMZOClient.loadKey(selected)
         .then(({ id, cert }) => {
            // Create a new certificate pkcs7
            EIMZOClient.createPkcs7(id, cert.CN)
               .then(res => {
                  onSuccess({
                     pin: cert.PINFL,
                     pkcs7: res
                  });
               })
               .catch(err => {
                  console.log(err);
               });
         })
         .catch(err => {
            console.log(err);
         });
   };

   useEffect(() => {
      EIMZOClient.install()
         .then(() => {
            EIMZOClient.listAllUserKeys()
               .then(res => {
                  setCertificates(res);
               })
               .catch(err => {
                  console.log(err);
               });
         })
         .catch(err => {
            console.log(err);
         });
   }, [EIMZOClient]);

   if (!open) {
      return null;
   }

   return (
      <div className={cls.modal} onClick={() => setOpen(false)}>
         <div
            className={cls.content}
            onClick={e => e.stopPropagation()}
         >
            <h2>E Imzo</h2>

            <Items
               items={certificates}
               selected={selected}
               setSelected={setSelected}
            />

            <div className={cls.btns}>
               <button
                  className={ft.close__btn}
                  onClick={() => setOpen(false)}
                  type='button'
               >
                  {Translate(lang, 'global.cancel')}
               </button>

               <button
                  className={ft.save__btn}
                  style={{display: 'flex', alignItems: 'center', columnGap: '5px'}}
                  onClick={onSign}
                  disabled={load}
               >
                  {load ? <Loading size='sm'/> : ''} Imzolash
               </button>
            </div>
         </div>
      </div>
   );
}

export default Modal;