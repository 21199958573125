import React, { useState, useEffect } from 'react';
import { ijroGovApi } from '../../../../services/ijroGovService';

const OrganizationTreeNode = ({ node, selectedIds, setSelectedIds, disabled }) => {
   const [isOpen, setIsOpen] = useState(false);
   const [isLoading, setIsLoading] = useState(false);
   const [childNodes, setChildNodes] = useState([]);

   useEffect(() => {
      if (isOpen && childNodes.length === 0) {
         setIsLoading(true);
         ijroGovApi.getChildOrgs(node.id)
            .then((res) => {
               if (res.data.success) {
                  setChildNodes(res.data.data);
               }
            })
            .catch((err) => {
               console.error('Error fetching child orgs: ', err);
            })
            .finally(() => {
               setIsLoading(false);
            });
      }
   }, [isOpen, node.id]);

   return (
      <div style={{ marginLeft: 20, marginBottom: 10 }}>
         <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', columnGap: 10 }}>
            <div>
               <input
                  onChange={() => {
                     if (selectedIds.includes(node.id)) {
                        setSelectedIds(selectedIds.filter((id) => id !== node.id));
                     } else {
                        setSelectedIds([...selectedIds, node.id]);
                     }
                  }}
                  checked={selectedIds.includes(node.id)}
                  type='checkbox'
                  disabled={disabled}
               />
            </div>

            <div style={{ marginRight: 'auto' }}>{node.name}</div>

            <div onClick={() => setIsOpen(!isOpen)}>
               {isOpen ? '▼' : '▶'}
            </div>
         </div>

         {isOpen && (
            <>
               {isLoading ? (
                  <div style={{ display: 'grid', placeItems: 'center', height: 200 }}>Loading...</div>
               ) : childNodes?.map((childNode) => (
                  <OrganizationTreeNode
                     node={childNode}
                     selectedIds={selectedIds}
                     setSelectedIds={setSelectedIds}
                     disabled={disabled || selectedIds.includes(node.id)}
                     key={childNode.id}
                  />
               ))}
            </>
         )}
      </div>
   );
};

export default OrganizationTreeNode;