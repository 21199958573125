import React, { useState, useEffect } from 'react';
import { ijroGovApi } from '../../../../services/ijroGovService';

import Translate from "../../../../components/lang";

import OrganizationTreeNode from './OrganizationTreeNode';

import ft from '../../../../components/mainStyles.module.scss';

const OrganizationTree = ({ mainOrgIds, setMainOrgIds, lang }) => {
   const [items, setItems] = useState([]);
   const [children, setChildren] = useState([]);
   const [isLoading, setIsLoading] = useState(false);
   const [selectedId, setSelectedId] = useState(null);

   useEffect(() => {
      setIsLoading(true);
      ijroGovApi.getOrgs()
         .then((res) => {
            if (res.data.success) {
               setItems(res.data.data);
            }
         })
         .catch((err) => {
            console.error('Error fetching orgs: ', err);
         })
         .finally(() => {
            setIsLoading(false);
         });
   }, []);

   return (
      <div>
         <div className={ft.lab}>{Translate(lang, 'global.org')}</div>

         {isLoading ? (
            <div style={{display: 'grid', placeItems: 'center', height: 200}}>Loading...</div>
         ) : items.map(item => (
            <div style={{marginBottom: 10}} key={item.id}>
            <div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center', columnGap: 10}}>
                  {/*<div>*/}
                  {/*   <input*/}
                  {/*      onChange={() => {*/}
                  {/*         if (mainOrgIds.includes(item.id)) {*/}
                  {/*            setMainOrgIds(mainOrgIds.filter((id) => id !== item.id));*/}
                  {/*         } else {*/}
                  {/*            setMainOrgIds([...mainOrgIds, item.id]);*/}
                  {/*         }*/}
                  {/*      }}*/}
                  {/*      checked={mainOrgIds.includes(item.id)}*/}
                  {/*      type='checkbox'*/}
                  {/*   />*/}
                  {/*</div>*/}

                  <div style={{marginRight: 'auto'}}>{item.name}</div>

                  <div
                     style={{ cursor: 'pointer' }}
                     onClick={() => {
                        if (selectedId === item.id) {
                           setSelectedId(null);
                           setChildren([]);
                        } else {
                           setSelectedId(item.id)
                           setChildren(item.children);
                        }
                     }}
                  >
                     {selectedId === item.id ? '▼' : '▶'}
                  </div>
               </div>

               {selectedId === item.id && children.map((node) => (
                  <OrganizationTreeNode
                     node={node}
                     setSelectedIds={setMainOrgIds}
                     selectedIds={mainOrgIds}
                     key={node.id}
                  />
               ))}
            </div>
         ))}
      </div>
   );
};

export default OrganizationTree;