import axiosInstance from './api';

const api = '/ijro-gov';

const	headers = {
	headers: {
		Authorization: `Bearer ${sessionStorage.getItem('token')}`
	}
};

export const ijroGovApi = {
	getOrgs: () => axiosInstance.get(`${api}/orgs`, headers),
	getChildOrgs: (parentId) => axiosInstance.get(`${api}/child-orgs/${parentId}`, headers),
	getList: (data) => axiosInstance.post(`${api}/list`, data, headers),
	getDocById: (id) => axiosInstance.get(`${api}/${id}`, headers),
	createDoc: (data) => axiosInstance.post(`${api}/doc`, data, headers),
	updateDoc: (id, data) => axiosInstance.put(`${api}/doc/${id}`, data, headers),
	deleteDoc: (id) => axiosInstance.delete(`${api}/doc/${id}`, headers),
	getAnswer: (id) => axiosInstance.get(`${api}/doc/${id}/answer`, headers),
	getListResponse: (data) => axiosInstance.post(`${api}/doc/response/list`, data, headers),
}
