import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Accordion, Button } from 'react-bootstrap';

import logo from '../../images/adliya-logo.png';
import Translate from '../lang';
import { chevron } from '../icons';

import ft from '../mainStyles.module.scss';
import st from './navbar.module.scss';

const home = (
		<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M3 9L12 2L21 9V20C21 20.5304 20.7893 21.0391 20.4142 21.4142C20.0391 21.7893 19.5304 22 19 22H5C4.46957 22 3.96086 21.7893 3.58579 21.4142C3.21071 21.0391 3 20.5304 3 20V9Z'
				stroke='white'
				strokeWidth='2'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path d='M9 22V12H15V22' stroke='white' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
		</svg>
	),
	orgList = (
		<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M2 3H8C9.06087 3 10.0783 3.42143 10.8284 4.17157C11.5786 4.92172 12 5.93913 12 7V21C12 20.2044 11.6839 19.4413 11.1213 18.8787C10.5587 18.3161 9.79565 18 9 18H2V3Z'
				stroke='white'
				strokeWidth='2'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M22 3H16C14.9391 3 13.9217 3.42143 13.1716 4.17157C12.4214 4.92172 12 5.93913 12 7V21C12 20.2044 12.3161 19.4413 12.8787 18.8787C13.4413 18.3161 14.2044 18 15 18H22V3Z'
				stroke='white'
				strokeWidth='2'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	),
	exp = (
		<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M17 21V19C17 17.9391 16.5786 16.9217 15.8284 16.1716C15.0783 15.4214 14.0609 15 13 15H5C3.93913 15 2.92172 15.4214 2.17157 16.1716C1.42143 16.9217 1 17.9391 1 19V21'
				stroke='white'
				strokeWidth='2'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M9 11C11.2091 11 13 9.20914 13 7C13 4.79086 11.2091 3 9 3C6.79086 3 5 4.79086 5 7C5 9.20914 6.79086 11 9 11Z'
				stroke='white'
				strokeWidth='2'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M23 21V19C22.9993 18.1137 22.7044 17.2528 22.1614 16.5523C21.6184 15.8519 20.8581 15.3516 20 15.13'
				stroke='white'
				strokeWidth='2'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M16 3.13C16.8604 3.35031 17.623 3.85071 18.1676 4.55232C18.7122 5.25392 19.0078 6.11683 19.0078 7.005C19.0078 7.89318 18.7122 8.75608 18.1676 9.45769C17.623 10.1593 16.8604 10.6597 16 10.88'
				stroke='white'
				strokeWidth='2'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	),
	sess = (
		<svg
			width='24'
			height='24'
			xmlns='http://www.w3.org/2000/svg'
			aria-hidden='true'
			focusable='false'
			data-prefix='fab'
			data-icon='wpforms'
			className='svg-inline--fa fa-wpforms fa-w-14'
			role='img'
			viewBox='0 0 448 512'>
			<path
				fill='currentColor'
				d='M448 75.2v361.7c0 24.3-19 43.2-43.2 43.2H43.2C19.3 480 0 461.4 0 436.8V75.2C0 51.1 18.8 32 43.2 32h361.7c24 0 43.1 18.8 43.1 43.2zm-37.3 361.6V75.2c0-3-2.6-5.8-5.8-5.8h-9.3L285.3 144 224 94.1 162.8 144 52.5 69.3h-9.3c-3.2 0-5.8 2.8-5.8 5.8v361.7c0 3 2.6 5.8 5.8 5.8h361.7c3.2.1 5.8-2.7 5.8-5.8zM150.2 186v37H76.7v-37h73.5zm0 74.4v37.3H76.7v-37.3h73.5zm11.1-147.3l54-43.7H96.8l64.5 43.7zm210 72.9v37h-196v-37h196zm0 74.4v37.3h-196v-37.3h196zm-84.6-147.3l64.5-43.7H232.8l53.9 43.7zM371.3 335v37.3h-99.4V335h99.4z'
			/>
		</svg>
	),
	logs = (
		<svg
			width='24'
			height='24'
			xmlns='http://www.w3.org/2000/svg'
			aria-hidden='true'
			focusable='false'
			data-prefix='fas'
			data-icon='clipboard-list'
			className='svg-inline--fa fa-clipboard-list fa-w-12'
			role='img'
			viewBox='0 0 384 512'>
			<path
				fill='currentColor'
				d='M336 64h-80c0-35.3-28.7-64-64-64s-64 28.7-64 64H48C21.5 64 0 85.5 0 112v352c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48V112c0-26.5-21.5-48-48-48zM96 424c-13.3 0-24-10.7-24-24s10.7-24 24-24 24 10.7 24 24-10.7 24-24 24zm0-96c-13.3 0-24-10.7-24-24s10.7-24 24-24 24 10.7 24 24-10.7 24-24 24zm0-96c-13.3 0-24-10.7-24-24s10.7-24 24-24 24 10.7 24 24-10.7 24-24 24zm96-192c13.3 0 24 10.7 24 24s-10.7 24-24 24-24-10.7-24-24 10.7-24 24-24zm128 368c0 4.4-3.6 8-8 8H168c-4.4 0-8-3.6-8-8v-16c0-4.4 3.6-8 8-8h144c4.4 0 8 3.6 8 8v16zm0-96c0 4.4-3.6 8-8 8H168c-4.4 0-8-3.6-8-8v-16c0-4.4 3.6-8 8-8h144c4.4 0 8 3.6 8 8v16zm0-96c0 4.4-3.6 8-8 8H168c-4.4 0-8-3.6-8-8v-16c0-4.4 3.6-8 8-8h144c4.4 0 8 3.6 8 8v16z'
			/>
		</svg>
	),
	tasks = (
		<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M16 4H18C18.5304 4 19.0391 4.21071 19.4142 4.58579C19.7893 4.96086 20 5.46957 20 6V20C20 20.5304 19.7893 21.0391 19.4142 21.4142C19.0391 21.7893 18.5304 22 18 22H6C5.46957 22 4.96086 21.7893 4.58579 21.4142C4.21071 21.0391 4 20.5304 4 20V6C4 5.46957 4.21071 4.96086 4.58579 4.58579C4.96086 4.21071 5.46957 4 6 4H8'
				stroke='white'
				strokeWidth='2'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M15 2H9C8.44772 2 8 2.44772 8 3V5C8 5.55228 8.44772 6 9 6H15C15.5523 6 16 5.55228 16 5V3C16 2.44772 15.5523 2 15 2Z'
				stroke='white'
				strokeWidth='2'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	),
	advices = (
		<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M14 2H6C5.46957 2 4.96086 2.21071 4.58579 2.58579C4.21071 2.96086 4 3.46957 4 4V20C4 20.5304 4.21071 21.0391 4.58579 21.4142C4.96086 21.7893 5.46957 22 6 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V8L14 2Z'
				stroke='white'
				strokeWidth='2'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path d='M14 2V8H20' stroke='white' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
			<path d='M16 13H8' stroke='white' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
			<path d='M16 17H8' stroke='white' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
			<path d='M10 9H9H8' stroke='white' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
		</svg>
	),
	markTimes = (
		<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z'
				stroke='white'
				strokeWidth='2'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path d='M12 6V12L16 14' stroke='white' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
		</svg>
	),
	infos = (
		<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z'
				stroke='white'
				strokeWidth='2'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M9.08984 8.99999C9.32495 8.33166 9.789 7.7681 10.3998 7.40912C11.0106 7.05015 11.7287 6.91893 12.427 7.0387C13.1253 7.15848 13.7587 7.52151 14.2149 8.06352C14.6712 8.60552 14.9209 9.29151 14.9198 9.99999C14.9198 12 11.9198 13 11.9198 13'
				stroke='white'
				strokeWidth='2'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path d='M12 17H12.01' stroke='white' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
		</svg>
	);

export default function Navbar(props) {
	const { user, lang, history, isOpen, setIsOpen } = props;
	const	[link, setLink] = useState('/');
	const [isCollapsed, setIsCollapsed] = useState(false);

	useEffect(() => {
		const navbar = document.querySelector('#navbar');
		const navbarTog = document.querySelector('#navbarToggler');
		if (!isOpen) {
			navbar.style = `width: 95px`;
			navbarTog.style = `left: 77px`;
		} else {
			navbar.style = `width: 300px`;
			navbarTog.style = `left: 282px`;
		}
	}, []);

	useEffect(() => {
		setLink(history.location.pathname);
	}, [history.location.pathname]);

	const toggler = () => {
		const navbar = document.querySelector('#navbar');
		const navbarTog = document.querySelector('#navbarToggler');
		if (navbar.style.width === '300px' || navbar.style.width === '') {
			setIsOpen(false);
			let temp = 300,
				left = 282;
			const g = setInterval(() => {
				temp -= 5;
				left -= 5;
				if (temp <= 95) clearInterval(g);
				navbar.style = `width: ${temp}px`;
				navbarTog.style = `left: ${left}px`;
			}, 5);
		} else {
			setIsOpen(true);
			let temp = 95,
				left = 77;
			const g = setInterval(() => {
				temp += 5;
				left += 5;
				if (temp >= 300) clearInterval(g);
				navbar.style = `width: ${temp}px`;
				navbarTog.style = `left: ${left}px`;
			}, 5);
		}
	};

	const toggleLink = (e) => {
		setLink(e);
	};

	return (
		<div className={st.navbar}>
			<div className={st.navbar__main} id='navbar'>
				<div className={st.nav}>
					<div className={st.navbar__head}>
						<div className={st.navbar__logo}>
							<img src={logo} />
						</div>
						<h5
							className={`mt-2 text-white ${isOpen ? '' : 'd-none'}`}
							style={{ fontWeight: 500, lineHeight: '27px' }}
						>
							{Translate(lang, 'navbar.title')}
						</h5>
					</div>

					<ul
						className={st.links}
						style={isOpen ? {} : { width: '60%', left: 0, right: 0, margin: '0 auto' }}
					>
						<Link
							className={`${st.link} ${link === '/' ? st.link__active : ''}`}
							style={isOpen ? {} : { justifyContent: 'center' }}
							onClick={() => toggleLink('/')}
							to='/'
						>
							<li>
								<span>{home}</span>
								<span className={`ml-3 ${isOpen ? '' : 'd-none'}`} style={{ lineHeight: '14px' }}>
									{Translate(lang, 'navbar.home')}
								</span>
							</li>
						</Link>

						<Link
							className={`${st.link} ${link === '/orgList' ? st.link__active : ''}`}
							style={isOpen ? {} : { justifyContent: 'center' }}
							onClick={() => toggleLink('/orgList')}
							to='/orgList'
						>
							<li>
								<span>{orgList}</span>
								<span className={`ml-3 ${isOpen ? '' : 'd-none'}`} style={{ lineHeight: '14px' }}>
									{Translate(lang, 'navbar.orgList')}
								</span>
							</li>
						</Link>

						{user?.role === 'admin' ? (
							<>
								<Link
									to='/orgTypes'
									className={`${st.link} ${link === '/orgTypes' ? st.link__active : ''}`}
									style={isOpen ? {} : { justifyContent: 'center' }}
									onClick={() => toggleLink('/orgTypes')}
								>
									<li>
										<span>{advices}</span>
										<span className={`ml-3 ${isOpen ? '' : 'd-none'}`} style={{ lineHeight: '14px' }}>
											{Translate(lang, 'navbar.orgTypes')}
										</span>
									</li>
								</Link>

								<Link
									className={`${st.link} ${link === '/experts' ? st.link__active : ''}`}
									style={isOpen ? {} : { justifyContent: 'center' }}
									onClick={() => toggleLink('/experts')}
									to='/experts'
								>
									<li>
										<span>{exp}</span>
										<span className={`ml-3 ${isOpen ? '' : 'd-none'}`} style={{ lineHeight: '14px' }}>
											{Translate(lang, 'navbar.experts')}
										</span>
									</li>
								</Link>
							</>
						) : null}

						<Link
							className={`${st.link} ${link === '/statistics' ? st.link__active : ''}`}
							style={isOpen ? {} : { justifyContent: 'center' }}
							onClick={() => toggleLink('/statistics')}
							to='/statistics'
						>
							<li>
								<span>{advices}</span>
								<span className={`ml-3 ${isOpen ? '' : 'd-none'}`} style={{ lineHeight: '14px' }}>
									{Translate(lang, 'navbar.statistics')}
								</span>
							</li>
						</Link>

						<Link
							className={`${st.link} ${link === '/propaganda-days' ? st.link__active : ''}`}
							style={isOpen ? {} : { justifyContent: 'center' }}
							onClick={() => toggleLink('/propaganda-days')}
							to='/propaganda-days'
						>
							<li>
								<span>{advices}</span>
								<span className={`ml-3 ${isOpen ? '' : 'd-none'}`} style={{ lineHeight: '14px' }}>
									{Translate(lang, 'navbar.propagandaDay')}
								</span>
							</li>
						</Link>

						<Link
							className={`${st.link} ${link === '/suggestions' ? st.link__active : ''}`}
							style={isOpen ? {} : { justifyContent: 'center' }}
							onClick={() => toggleLink('/suggestions')}
							to='/suggestions'
						>
							<li>
								<span>{advices}</span>
								<span className={`ml-3 ${isOpen ? '' : 'd-none'}`} style={{ lineHeight: '14px' }}>
									{Translate(lang, 'navbar.suggestions')}
								</span>
							</li>
						</Link>

						<Link
							className={`${st.link} ${link === '/neighborhoods' ? st.link__active : ''}`}
							style={isOpen ? {} : { justifyContent: 'center' }}
							onClick={() => toggleLink('/neighborhoods')}
							to='/neighborhoods'
						>
							<li>
								<span>{advices}</span>
								<span className={`ml-3 ${isOpen ? '' : 'd-none'}`} style={{ lineHeight: '14px' }}>
									{Translate(lang, 'navbar.sendDocToNeighborhood')}
								</span>
							</li>
						</Link>

						<Link
							className={`${st.link} ${link === '/ijro-gov/docs' ? st.link__active : ''}`}
							style={isOpen ? {} : { justifyContent: 'center' }}
							onClick={() => toggleLink('/ijro-gov/docs')}
							to='/ijro-gov/docs'
						>
							<li>
								<span>{advices}</span>
								<span className={`ml-3 ${isOpen ? '' : 'd-none'}`} style={{ lineHeight: '14px' }}>
									{Translate(lang, 'navbar.ijroGov')}
								</span>
							</li>
						</Link>

						{isOpen ? (
							<Accordion>
								<Accordion.Toggle
									className={`d-flex justify-content-between align-items-center text-left ${st.list_link}`}
									onClick={() => setIsCollapsed(!isCollapsed)}
									variant='link'
									eventKey='0'
									as={Button}
								>
									<div>
										<span>{tasks}</span>
										<span
											className={`ml-3 ${isOpen ? '' : 'd-none'}`}
											style={{ lineHeight: '14px' }}
										>
											{Translate(lang, 'navbar.normativeDocMenu')}
										</span>
									</div>
									<div>
										{chevron(null, ft.accordion__icon, ft.accordion__icon__active, isCollapsed, 'fff')}
									</div>
								</Accordion.Toggle>

								<Accordion.Collapse eventKey='0'>
									<>
										<Link
											className={`${st.link} ${link === '/normative-docs/additional-received-monitoring' ? st.link__active : ''}`}
											style={isOpen ? {} : { justifyContent: 'center' }}
											onClick={() => toggleLink('/normative-docs/additional-received-monitoring')}
											to='/normative-docs/additional-received-monitoring'
										>
											<li style={isOpen ? {} : { width: '75%' }}>
												<span
													className={`${isOpen ? '' : 'd-none'}`}
													style={{ lineHeight: '14px', marginLeft: '40px' }}
												>
													{Translate(lang, 'navbar.additionalReceivedMonitoring')}
												</span>
											</li>
										</Link>

										<Link
											className={`${st.link} ${link === '/normative-docs/docs' ? st.link__active : ''}`}
											style={isOpen ? {} : { justifyContent: 'center' }}
											onClick={() => toggleLink('/normative-docs/docs')}
											to='/normative-docs/docs'
										>
											<li style={isOpen ? {} : { width: '75%' }}>
												<span
													className={`${isOpen ? '' : 'd-none'}`}
													style={{ lineHeight: '14px', marginLeft: '40px' }}
												>
													{Translate(lang, 'navbar.normativeDocs')}
												</span>
											</li>
										</Link>

										<Link
											className={`${st.link} ${link === '/normative-docs/doc-receiveds' ? st.link__active : ''}`}
											style={isOpen ? {} : { justifyContent: 'center' }}
											onClick={() => toggleLink('/normative-docs/doc-receiveds')}
											to='/normative-docs/doc-receiveds'
										>
											<li style={isOpen ? {} : { width: '75%' }}>
												<span
													className={`${isOpen ? '' : 'd-none'}`}
													style={{ lineHeight: '14px', marginLeft: '40px' }}
												>
													{Translate(lang, 'navbar.normativeDocReceiveds')}
												</span>
											</li>
										</Link>

										<Link
											className={`${st.link} ${link === '/normative-docs/main-received-task-monitoring' ? st.link__active : ''}`}
											style={isOpen ? {} : { justifyContent: 'center' }}
											onClick={() => toggleLink('/normative-docs/main-received-task-monitoring')}
											to='/normative-docs/main-received-task-monitoring'
										>
											<li style={isOpen ? {} : { width: '75%' }}>
												<span
													className={`${isOpen ? '' : 'd-none'}`}
													style={{ lineHeight: '14px', marginLeft: '40px' }}
												>
													{Translate(lang, 'navbar.statTask')}
												</span>
											</li>
										</Link>

										<Link
											className={`${st.link} ${link === '/normative-docs/doc-tasks' ? st.link__active : ''}`}
											style={isOpen ? {} : { justifyContent: 'center' }}
											onClick={() => toggleLink('/normative-docs/doc-tasks')}
											to='/normative-docs/doc-tasks'
										>
											<li style={isOpen ? {} : { width: '75%' }}>
												<span
													className={`${isOpen ? '' : 'd-none'}`}
													style={{ lineHeight: '14px', marginLeft: '40px' }}
												>
													{Translate(lang, 'navbar.normativeDocTasks')}
												</span>
											</li>
										</Link>
									</>
								</Accordion.Collapse>
							</Accordion>
						) : (
							<div className={st.view}>
								<div className={`${st.view__icon} ${link.slice(0, 6) === '/normative-docs' ? st.link__active : ''}`}>
									{tasks}
								</div>
								<div className={st.view__item}>
									<ul className={st.accordion_item}>
										<Link
											className={`${st.link} ${link === '/normative-docs/additional-received-monitoring' ? st.link__active : ''}`}
											style={isOpen ? {} : { justifyContent: 'center' }}
											onClick={() => toggleLink('/normative-docs/additional-received-monitoring')}
											to='/normative-docs/additional-received-monitoring'
										>
											<li>
												<span>{Translate(lang, 'navbar.additionalReceivedMonitoring')}</span>
											</li>
										</Link>

										<Link
											className={`${st.link} ${link === '/normative-docs/docs' ? st.link__active : ''}`}
											style={isOpen ? {} : { justifyContent: 'center' }}
											onClick={() => toggleLink('/normative-docs/docs')}
											to='/normative-docs/docs'
										>
											<li>
												<span>
													{Translate(lang, 'navbar.normativeDocs')}
												</span>
											</li>
										</Link>

										<Link
											className={`${st.link} ${link === '/normative-docs/doc-receiveds' ? st.link__active : ''}`}
											style={isOpen ? {} : { justifyContent: 'center' }}
											onClick={() => toggleLink('/normative-docs/doc-receiveds')}
											to='/normative-docs/doc-receiveds'
										>
											<li>
												<span>
													{Translate(lang, 'navbar.normativeDocReceiveds')}
												</span>
											</li>
										</Link>

										<Link
											className={`${st.link} ${link === '/normative-docs/main-received-task-monitoring' ? st.link__active : ''}`}
											style={isOpen ? {} : { justifyContent: 'center' }}
											onClick={() => toggleLink('/normative-docs/main-received-task-monitoring')}
											to='/normative-docs/main-received-task-monitoring'
										>
											<li>
												<span>
													{Translate(lang, 'navbar.statTask')}
												</span>
											</li>
										</Link>

										<Link
											className={`${st.link} ${link === '/normative-docs/doc-tasks' ? st.link__active : ''}`}
											style={isOpen ? {} : { justifyContent: 'center' }}
											onClick={() => toggleLink('/normative-docs/doc-tasks')}
											to='/normative-docs/doc-tasks'
										>
											<li>
												<span>
													{Translate(lang, 'navbar.normativeDocTasks')}
												</span>
											</li>
										</Link>
									</ul>
								</div>
							</div>
						)}

						<Link
							className={`${st.link} ${link === '/home/all' ? st.link__active : ''}`}
							style={isOpen ? {} : { justifyContent: 'center' }}
							onClick={() => toggleLink('/home/all')}
							to='/home/all'
						>
							<li>
								<span>{home}</span>
								<span className={`ml-3 ${isOpen ? '' : 'd-none'}`} style={{ lineHeight: '14px' }}>
									{Translate(lang, 'navbar.homeAll')}
								</span>
							</li>
						</Link>

						{user?.role === 'admin' ? (
							<>
								<Link
									to='/sessions'
									className={`${st.link} ${link === '/sessions' ? st.link__active : ''}`}
									style={isOpen ? {} : { justifyContent: 'center' }}
									onClick={() => toggleLink('/sessions')}
								>
									<li>
										<span>{sess}</span>
										<span
											className={`ml-3 ${isOpen ? '' : 'd-none'}`}
											style={{ lineHeight: '14px' }}>
											{Translate(lang, 'navbar.sessions')}
										</span>
									</li>
								</Link>

								<Link
									to='/logs'
									className={`${st.link} ${link === '/logs' ? st.link__active : ''}`}
									style={isOpen ? {} : { justifyContent: 'center' }}
									onClick={() => toggleLink('/logs')}
								>
									<li>
										<span>{logs}</span>
										<span
											className={`ml-3 ${isOpen ? '' : 'd-none'}`}
											style={{ lineHeight: '14px' }}>
											{Translate(lang, 'navbar.logs')}
										</span>
									</li>
								</Link>
							</>
						) : null}

						{isOpen ? (
							<Accordion>
								<Accordion.Toggle
									className={`d-flex justify-content-between align-items-center text-left ${st.list_link}`}
									onClick={() => setIsCollapsed(!isCollapsed)}
									variant='link'
									eventKey='0'
									as={Button}
								>
									<div>
										<span>{tasks}</span>
										<span
											className={`ml-3 ${isOpen ? '' : 'd-none'}`}
											style={{ lineHeight: '14px' }}
										>
											{Translate(lang, 'navbar.tasks')}
										</span>
									</div>
									<div>
										{chevron(null, ft.accordion__icon, ft.accordion__icon__active, isCollapsed, 'fff')}
									</div>
								</Accordion.Toggle>

								<Accordion.Collapse eventKey='0'>
									<>
										<Link
											to='/tasks/send'
											className={`${st.link} ${link === '/tasks/send' ? st.link__active : ''}`}
											style={isOpen ? {} : { justifyContent: 'center' }}
											onClick={() => toggleLink('/tasks/send')}
										>
											<li style={isOpen ? {} : { width: '75%' }}>
												<span
													className={`${isOpen ? '' : 'd-none'}`}
													style={{ lineHeight: '14px', marginLeft: '40px' }}
												>
													{Translate(lang, 'navbar.sendTask')}
												</span>
											</li>
										</Link>

										<Link
											className={`${st.link} ${link === '/tasks/check' ? st.link__active : ''}`}
											style={isOpen ? {} : { justifyContent: 'center' }}
											onClick={() => toggleLink('/tasks/check')}
											to='/tasks/check'
										>
											<li style={isOpen ? {} : { width: '75%' }}>
												<span
													className={`${isOpen ? '' : 'd-none'}`}
													style={{ lineHeight: '14px', marginLeft: '40px' }}
												>
													{Translate(lang, 'navbar.checkTask')}
												</span>
											</li>
										</Link>

										<Link
											className={`${st.link} ${link === '/tasks/stat' ? st.link__active : ''}`}
											style={isOpen ? {} : { justifyContent: 'center' }}
											onClick={() => toggleLink('/tasks/stat')}
											to='/tasks/stat'
										>
											<li style={isOpen ? {} : { width: '75%' }}>
												<span
													className={`${isOpen ? '' : 'd-none'}`}
													style={{ lineHeight: '14px', marginLeft: '40px' }}
												>
													{Translate(lang, 'navbar.statTask')}
												</span>
											</li>
										</Link>
									</>
								</Accordion.Collapse>
							</Accordion>
						) : (
							<div className={`${st.view} `}>
								<div className={`${st.view__icon} ${link.slice(0, 6) === '/tasks' ? st.link__active : ''}`}>
									{tasks}
								</div>
								<div className={st.view__item}>
									<ul className={st.accordion_item}>
										<Link
											className={`${st.link} ${link === '/tasks/send' ? st.link__active : ''}`}
											style={isOpen ? {} : { justifyContent: 'center' }}
											onClick={() => toggleLink('/tasks/send')}
											to='/tasks/send'
										>
											<li>
												<span>{Translate(lang, 'navbar.sendTask')}</span>
											</li>
										</Link>

										<Link
											className={`${st.link} ${link === '/tasks/check' ? st.link__active : ''}`}
											style={isOpen ? {} : { justifyContent: 'center' }}
											onClick={() => toggleLink('/tasks/check')}
											to='/tasks/check'
										>
											<li>
												<span>{Translate(lang, 'navbar.checkTask')}</span>
											</li>
										</Link>

										<Link
											className={`${st.link} ${link === '/tasks/stat' ? st.link__active : ''}`}
											style={isOpen ? {} : { justifyContent: 'center' }}
											onClick={() => toggleLink('/tasks/stat')}
											to='/tasks/stat'
										>
											<li>
												<span>{Translate(lang, 'navbar.statTask')}</span>
											</li>
										</Link>
									</ul>
								</div>
							</div>
						)}

						<Link
							className={`${st.link} ${link === '/advices' ? st.link__active : ''}`}
							style={isOpen ? {} : { justifyContent: 'center' }}
							onClick={() => toggleLink('/advices')}
							to='/advices'
						>
							<li>
								<span>{advices}</span>
								<span className={`ml-3 ${isOpen ? '' : 'd-none'}`} style={{ lineHeight: '14px' }}>
									{Translate(lang, 'navbar.tav')}
								</span>
							</li>
						</Link>

						{user?.role === 'admin' ? (
							<Link
								className={`${st.link} ${link === '/markTimes' ? st.link__active : ''}`}
								style={isOpen ? {} : { justifyContent: 'center' }}
								onClick={() => toggleLink('/markTimes')}
								to='/markTimes'
							>
								<li>
									<span>{markTimes}</span>
									<span className={`ml-3 ${isOpen ? '' : 'd-none'}`} style={{ lineHeight: '14px' }}>
										{Translate(lang, 'navbar.tdate')}
									</span>
								</li>
							</Link>
						) : null}

						<Link
							className={`${st.link} ${link === '/infos' ? st.link__active : ''}`}
							style={isOpen ? {} : { justifyContent: 'center' }}
							onClick={() => toggleLink('/infos')}
							to='/infos'
						>
							<li>
								<span>{infos}</span>
								<span className={`ml-3 ${isOpen ? '' : 'd-none'}`} style={{ lineHeight: '14px' }}>
									{Translate(lang, 'navbar.yoq')}
								</span>
							</li>
						</Link>
					</ul>

					<div id='navbarToggler' className={st.navbar__toggler} onClick={toggler}>
						{chevron(toggler, st.navbar__toggler__icon, st.navbar__toggler__icon__act, isOpen, '07A287')}
					</div>
				</div>
			</div>
		</div>
	);
}
