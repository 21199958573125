import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';

import config from '../../config';

import Translate from '../lang';

import { del, fileIcon, filesIcon, imagesIcon, imgIcon } from '../icons';

import st from './uploadFiles.module.scss';
import ft from '../uploadImage/uploadImage.module.scss';

const UploadFiles = ({
	title = 'global.fileAndImg',
	multiple = true,
	accept,
	lang,
	selFiles,
	setSelFiles,
	newFiles,
	setNewFiles,
	prots,
	setProts,
	handleDeleteFile,
	setErrFiles,
	setUploadedFiles
}) => {
	const fileInput = useRef();
	const [send, setSend] = useState([]);
	const [previews, setPreviews] = useState([]);
	const [isDrag, setDrag] = useState(false);
	const [uploading, setUploading] = useState(false);

	const handleDrop = (e) => {
		e.preventDefault();

		const draggedFiles = [];

		if (e.dataTransfer.items) {
			Array.from(e.dataTransfer.items).forEach((item) => {
				if (item.kind === 'file') {
					let file = item.getAsFile();
					draggedFiles.push(file);
				}
			})
		} else {
			Array.from(e.dataTransfer.files).forEach((file) => {
				draggedFiles.push(file);
			});
		}

		setNewFiles([...newFiles, ...draggedFiles]);
		setDrag(false);
	}

	const handleChange = (e) => {
		setNewFiles([...newFiles, ...Array.from(e.target.files)]);
		setProts(prev => {
			let temp = [...prev];
			Array.from(e.target.files).forEach(() => temp.push(0));
			return temp;
		})
	}

	const handleUpload = () => {
		if (!newFiles.length) {
			return;
		}

		newFiles?.forEach((file, index) => {
			setUploading(true);
			setErrFiles(false);

			if (!file.success) {
				const data = new FormData();

				const source = axios.CancelToken.source();

				data.append('file', file);

				axios
					.post(`${config.api.baseUrl}/api/file/upload`, data, {
						onUploadProgress: function (progressEvent) {
							setProts((prev) => {
								prev[index] = Math.round((progressEvent.loaded * 100) / progressEvent.total);
								return [...prev];
							});
							setSend((prev) => {
								prev[index] = source;
								return [...prev];
							});
						},
						cancelToken: source.token,
					})
					.then(res => {
						setNewFiles((prev) => {
							let temp = [...prev];
							temp[index].success = true;
							return temp;
						});
						setUploadedFiles(prev => {
							let temp = [...prev];
							temp.push(res.data.data);
							return temp;
						});
						setUploading(false);
					})
					.catch(() => {
						setUploading(false);
					})
			} else {
				setUploading(false);
			}
		})
	}

	const handleDragOver = (e) => {
		e.preventDefault();
		setDrag(true);
	}

	const handleDragLeave = (e) => {
		e.preventDefault();
		setDrag(false);
	}

	const openFileDialog = () => {
		fileInput.current.click();
	}

	useEffect(() => {
		async function getPreviews() {
			if (newFiles?.type) {
				const promises = newFiles.map(getPreview);
				setPreviews(await Promise.all(promises));
			} else {
				setPreviews(() => {
					let temp = [];
					newFiles.forEach((f) => {
						if (f?.name?.includes('.jpg') || f?.name?.includes('.png')) {
							temp.push(imagesIcon);
						} else {
							temp.push(filesIcon);
						}
					})
					return temp;
				});
			}
		}

		getPreviews();
	}, [newFiles]);

	return (
		<div className={st.uploadFiles}>
			<div
				className={`file-upload-container ${isDrag ? 'drag' : ''} ${uploading ? 'uploading' : ''}`}
				onDrop={handleDrop}
				onDragOver={handleDragOver}
				onDragLeave={handleDragLeave}
			>
				<input
					ref={fileInput}
					onChange={handleChange}
					type='file'
					multiple={multiple}
					accept={accept && accept.join(',')}
				/>

				<button
					className={`${ft.upload__btn}`}
					onClick={openFileDialog}
					disabled={uploading}
					type='button'
				>
					<span>{fileIcon}</span> <span className='ml-2'>{imgIcon}</span>
					<p className='mt-2'>{Translate(lang, title)}</p>
				</button>

				<ul className='upload-preview'>
					{!!selFiles?.length && selFiles?.map(file => (
						<li key={file.name}>
							<div className={st.svg}>
								{file.name.includes('.png') || file.name.includes('.jpg') || file.name.includes('.bmp') || file.name.includes('.webp') ? imagesIcon : filesIcon}
							</div>

							<div className='w-100 px-2'>
								<div>{file.name}</div>
								<div
									style={{
										position: 'relative',
										height: '6px',
										width: '100%',
										marginTop: 5,
										backgroundColor: '#eee',
										borderRadius: '50px',
									}}
								>
									<div
										style={{
											position: 'absolute',
											top: 0,
											left: 0,
											width: '100%',
											height: '6px',
											backgroundColor: '#07A287',
											borderRadius: '50px',
										}}
									/>
								</div>
							</div>

							<div>
								<button
									className={ft.del__btn}
									style={{
										height: 'fit-content',
										padding: 4,
										backgroundColor: 'rgba(255, 82, 82, 0.1)',
										borderRadius: 4,
									}}
									onClick={() => handleDeleteFile(file._id)}
									type='button'
								>
									{del}
								</button>
							</div>
						</li>
					))}

					{!!newFiles?.length && newFiles?.map((file, i) => (
						<li key={file.name}>
							<div className={st.svg}>{previews[i]}</div>

							<div
								className='px-2'
								style={{width: '100%', overflow: 'hidden'}}
							>
								<div>{file.name}</div>
								<div
									style={{
										position: 'relative',
										height: '6px',
										width: '100%',
										marginTop: 5,
										backgroundColor: '#eee',
										borderRadius: '50px',
									}}
								>
									<div
										style={{
											position: 'absolute',
											top: 0,
											left: 0,
											width: `${prots[i]}%`,
											height: '6px',
											backgroundColor: '#07A287',
											borderRadius: '50px',
										}}
									/>
								</div>
							</div>

							<div className='ml-auto'>
								<button
									className={ft.del__btn}
									style={{
										height: 'fit-content',
										backgroundColor: 'rgba(255, 82, 82, 0.1)',
										borderRadius: 4,
										padding: 4
									}}
									onClick={() => {
										setNewFiles(prev => {
											let temp = [...prev];
											temp.splice(i, 1);
											return temp;
											});
											if (file.success) {
												setUploadedFiles(prev => {
													let temp = [...prev];
													temp.splice(i, 1);
													return temp;
												});
											}
											setProts(prev => {
												let temp = [...prev];
												temp.splice(i, 1);
												return temp;
											})
											send[i]?.cancel();
										}}
										type='button'
									>
										{del}
									</button>
								</div>
							</li>
					))}
				</ul>
			</div>

			{newFiles.length ? (
				<button
					className='upload-btn'
					onClick={handleUpload}
					disabled={uploading}
					type='button'
				>
					{Translate(lang, 'global.download')}
				</button>
			) : null}
		</div>
	)
}

export default UploadFiles;

function getPreview(file) {
	return new Promise((resolve) => {
		if (file && file.type.includes('image')) {
			resolve(imagesIcon)
		} else {
			resolve(filesIcon)
		}
	})
}
