import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { docApi } from '../../../services/doc/docService';
import { ijroGovApi } from '../../../services/ijroGovService';

import Translate from '../../../components/lang';
import Loading from '../../../components/loading';
import Container from '../../../components/container';

import ft from '../../../components/mainStyles.module.scss';

const Answer = ({ lang }) => {
   const { docId } = useParams();
   const history = useHistory();
   const [data, setData] = useState(null);
   const [doc, setDoc] = useState({});
   const [isLoading, setIsLoading] = useState(false);

   useEffect(() => {
      docApi.getById(docId)
         .then(res => {
            if (res.data.success) {
               setDoc(res.data.data);
            }
         })
         .catch(err => {
            console.log(err);
         });
   }, []);

   useEffect(() => {
      setIsLoading(true);
      ijroGovApi.getAnswer(docId)
         .then((res) => {
            if (res.data.success) {
               setData(res.data.data);
            }
         })
         .catch((err) => {
            console.error('Error fetching answer: ', err);
         })
         .finally(() => {
            setIsLoading(false);
         });
   }, []);

   return (
      <Container>
         <div className={ft.content__head}>
            <h5 className={ft.title}>Monitoring ({doc.number})</h5>

            <div className={ft.btns}>
               <button
                  className={`${ft.close__btn} ml-2`}
                  onClick={() => history.goBack()}
               >
                  {Translate(lang, 'global.cancel')}
               </button>
            </div>
         </div>

         <div className={ft.table}>
            <table className={ft.table__main}>
               <thead>
                  <tr>
                     <td>#</td>
                     <td>FIO</td>
                     <td>Lavozimi</td>
                     <td>Tashkiloti</td>
                  </tr>
               </thead>

               <tbody>
                  {!isLoading ? (
                     data?.map((item, index) => (
                        <tr key={index}>
                           <td>{index + 1}</td>
                           <td>{item.user.full_name}</td>
                           <td>{item.user.position.uz_latn}</td>
                           <td>{item.organization.name_uz}</td>
                        </tr>
                     ))
                  ) : (
                     <tr>
                        <td colSpan={8}>
                           <Loading cl='mt-3' big={true} size='md'/>
                        </td>
                     </tr>
                  )}
               </tbody>
            </table>

            {!isLoading && !data?.length && (
               <h6 className={ft.noData}>{Translate(lang, 'table.notFound')}</h6>
            )}
         </div>
      </Container>
   );
};

export default Answer;